<template>
  <div class="settings-content-wrapper">
    <breadcrumbs :breadcrumbs="breadcrumbs"></breadcrumbs>
    <dashboard-navigation-group v-for="(group, index) in groups" v-bind:key="index" :group="group"></dashboard-navigation-group>
  </div>

</template>

<style>

</style>

<script>

import DashboardNavigationGroup from '../DashboardNavigationGroup.vue'
import Breadcrumbs from '../Breadcrumbs.vue'

export default {
  name: 'settings-home',
  components: { DashboardNavigationGroup, Breadcrumbs },
  data () {
    return {
      breadcrumbs: [
        { name: 'Settings', route: null }
      ],
      groups: [
        {
          name: 'My Account',
          items: [
            { name: 'Business Details', icon: 'settings-business-details', route: 'settings-account-details', description: 'All the important information about your business.' },
            { name: 'Theme', icon: 'settings-theme', route: 'settings-theme', description: 'Configure your logo and brand colors.' },
            { name: 'Opening Hours', icon: 'settings-opening-hours', route: 'settings-availability', description: 'Set your operating hours for online bookings.' },
            { name: 'Change Password', icon: 'settings-change-password', route: 'settings-change-password', description: 'Change the password for your Bunya user.' },
            { name: 'User Accounts', icon: 'settings-user-accounts', route: 'settings-users', description: 'Manage users for your Bunya account.' },
            { name: 'Bunya Subscription', icon: 'settings-subscription', route: 'settings-subscription', description: 'Manage your subscription to Bunya.' }
          ]
        },
        {
          name: 'Payments',
          items: [
            { name: 'Invoice Settings', icon: 'settings-invoice-settings', route: 'settings-invoice-settings', description: 'Setup your default settings for sending an invoice.' },
            { name: 'Online Payments', icon: 'settings-online-payments', route: 'settings-stripe', description: 'Let your customers pay you with a credit card.' },
            { name: 'Tax Rates', icon: 'settings-tax-rates', route: 'settings-tax-rate-groups', description: 'Configure custom tax rates.' }
          ]
        },
        {
          name: 'Products & Services',
          items: [
            { name: 'Categories', icon: 'settings-categories', route: 'settings-service-categories', description: 'Define categories for the services you offer.' },
            { name: 'Services', icon: 'settings-services-offered', route: 'settings-services', description: 'Configure the services you provide.' }
          ]
        }
      ]
    }
  }
}

</script>
